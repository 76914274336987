var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-expansion-panels',{attrs:{"readonly":"","multiple":"","flat":""},model:{value:(_setup.expanded),callback:function ($$v) {_setup.expanded=$$v},expression:"expanded"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.products[_vm.productIndex]?.name)+"の物理的及び化学的性質")])]),_c('v-divider'),_c('v-expansion-panel-content',{staticClass:"pt-3 primary-bg"},[_c('div',{staticClass:"detail-table"},[_c('v-data-table',{attrs:{"headers":_setup.headerSection9,"items":_vm.section9,"items-per-page":_vm.section9.length,"hide-default-footer":"","no-data-text":"データが登録されていません","data-cy":"sds_table_section3_admin_section9_data-table"},scopedSlots:_vm._u([{key:"item.condition",fn:function({ item }){return [_c('span',{style:({
                  textDecoration: item.deleted_at ? 'line-through' : 'none',
                })},[_vm._v(" "+_vm._s(item.condition)+" ")])]}},{key:"item.physicalCondition",fn:function({ item }){return [_c('span',{style:({
                  textDecoration: item.deleted_at ? 'line-through' : 'none',
                })},[_vm._v(" "+_vm._s(item.physical_condition)+" ")])]}},{key:"item.form",fn:function({ item }){return [_c('span',{style:({
                  textDecoration: item.deleted_at ? 'line-through' : 'none',
                })},[_vm._v(" "+_vm._s(item.form)+" ")])]}},(_vm.isEditing)?{key:"item.action",fn:function({ item, index }){return [_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":!_vm.isEditing || !!item.deleted_at,"x-small":"","fab":"","depressed":"","outlined":"","color":"primary"},on:{"click":function($event){_setup.section9Index = index;
                  _setup.openEditSection9Modal();
                  _setup.bindSection9();}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),(!item.deleted_at)?_c('v-btn',{attrs:{"disabled":!_vm.isEditing || !!item.deleted_at,"x-small":"","fab":"","depressed":"","outlined":"","color":"error"},on:{"click":function($event){_setup.openDeleteSection9Modal();
                  _setup.section9Index = index;}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_c('v-btn',{attrs:{"disabled":!_vm.isEditing || !item.deleted_at,"x-small":"","fab":"","depressed":"","outlined":"","color":"primary"},on:{"click":function($event){_setup.section9Index = index;
                  _setup.openRescueSection9Modal();}}},[_c('v-icon',[_vm._v("mdi-delete-clock-outline")])],1)]}}:null,{key:"foot",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"8"}},[(_vm.isEditing)?_c('v-btn',{attrs:{"block":"","outlined":"","color":"primary","width":"100%"},on:{"click":_setup.openAddSection9Modal}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v("物理的・化学的性質を追加 ")],1):_vm._e()],1)])]},proxy:true}],null,true)})],1)])],1)],1),(_vm.isEditing)?_c('v-dialog',{attrs:{"width":"500"},model:{value:(_setup.modalDeleteSection9),callback:function ($$v) {_setup.modalDeleteSection9=$$v},expression:"modalDeleteSection9"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 lighten-2"},[_vm._v(" 本当に削除しますか ")]),_c('v-divider'),_c('v-card-text',[_vm._v(" 「削除する」をクリックすると、データがDBから削除されます。この操作は戻すことができません。"),_c('br')]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_setup.deleteSection9();
            _setup.closeDeleteSection9Modal();}}},[_vm._v(" 削除する ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"cancel","text":""},on:{"click":_setup.closeDeleteSection9Modal}},[_vm._v(" 戻る ")])],1)],1)],1):_vm._e(),(_vm.isEditing)?_c('v-dialog',{attrs:{"width":"500"},model:{value:(_setup.modalRescueSection9),callback:function ($$v) {_setup.modalRescueSection9=$$v},expression:"modalRescueSection9"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 lighten-2"},[_vm._v(" 第9章のデータの削除フラグを外しますか ")]),_c('v-divider'),_c('v-card-text',[_vm._v(" 「削除フラグを外す」をクリックすると、データに付与された削除フラグが外されます。"),_c('br')]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"cancel","text":""},on:{"click":_setup.closeRescueSection9Modal}},[_vm._v(" 戻る ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_setup.rescueSection9();
            _setup.closeRescueSection9Modal();}}},[_vm._v(" 削除フラグを外す ")])],1)],1)],1):_vm._e(),(_vm.isEditing)?_c('v-dialog',{model:{value:(_setup.modalAddSection9),callback:function ($$v) {_setup.modalAddSection9=$$v},expression:"modalAddSection9"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 lighten-2"},[_vm._v(" 追加する第9章のデータを入力してください ")]),_c('v-divider'),_c('v-card-text',[_c(_setup.SdsSection9Form,{attrs:{"is-valid":_setup.isValidNewSection9,"is-editing":_vm.isEditing,"condition":_setup.newSection9.condition,"physical-condition":_setup.newSection9.physical_condition,"form":_setup.newSection9.form},on:{"update:isValid":function($event){_setup.isValidNewSection9=$event},"update:is-valid":function($event){_setup.isValidNewSection9=$event},"update:condition":function($event){return _vm.$set(_setup.newSection9, "condition", $event)},"update:physicalCondition":function($event){return _vm.$set(_setup.newSection9, "physical_condition", $event)},"update:physical-condition":function($event){return _vm.$set(_setup.newSection9, "physical_condition", $event)},"update:form":function($event){return _vm.$set(_setup.newSection9, "form", $event)}}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"cancel","text":""},on:{"click":_setup.closeAddSection9Modal}},[_vm._v(" 戻る ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_setup.isValidNewSection9},on:{"click":function($event){_setup.addSection9();
            _setup.clearNewSection9Form();
            _setup.closeAddSection9Modal();}}},[_vm._v(" 追加する ")])],1)],1)],1):_vm._e(),(_vm.isEditing)?_c('v-dialog',{model:{value:(_setup.modalEditSection9),callback:function ($$v) {_setup.modalEditSection9=$$v},expression:"modalEditSection9"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 lighten-2"},[_vm._v(" 第9章のデータを編集してください ")]),_c('v-divider'),_c('v-card-text',[_c(_setup.SdsSection9Form,{attrs:{"is-valid":_setup.isValidUpdatedSection9,"is-editing":_vm.isEditing,"condition":_setup.updatedSection9.condition,"physical-condition":_setup.updatedSection9.physical_condition,"form":_setup.updatedSection9.form},on:{"update:isValid":function($event){_setup.isValidUpdatedSection9=$event},"update:is-valid":function($event){_setup.isValidUpdatedSection9=$event},"update:condition":function($event){return _vm.$set(_setup.updatedSection9, "condition", $event)},"update:physicalCondition":function($event){return _vm.$set(_setup.updatedSection9, "physical_condition", $event)},"update:physical-condition":function($event){return _vm.$set(_setup.updatedSection9, "physical_condition", $event)},"update:form":function($event){return _vm.$set(_setup.updatedSection9, "form", $event)}}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"cancel","text":""},on:{"click":_setup.closeEditSection9Modal}},[_vm._v(" 戻る ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_setup.isValidUpdatedSection9},on:{"click":function($event){_setup.editSection9();
            _setup.closeEditSection9Modal();}}},[_vm._v(" 変更する ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }