import { render, staticRenderFns } from "./SdsTableSectionAccordion.vue?vue&type=template&id=172c9088&scoped=true"
import script from "./SdsTableSectionAccordion.vue?vue&type=script&setup=true&lang=ts"
export * from "./SdsTableSectionAccordion.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SdsTableSectionAccordion.vue?vue&type=style&index=0&id=172c9088&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "172c9088",
  null
  
)

export default component.exports