var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-data-table',{staticClass:"pa-5",attrs:{"headers":_setup.headerSection1,"items":_vm.manufacturers,"items-per-page":_vm.manufacturers.length,"hide-default-footer":"","no-data-text":"データが登録されていません","data-cy":"sds_table_section1_admin_section1__manufacturer_data-table"},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_c('span',{style:({ textDecoration: item.deleted_at ? 'line-through' : 'none' })},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.address",fn:function({ item }){return [_c('span',{style:({ textDecoration: item.deleted_at ? 'line-through' : 'none' })},[_vm._v(" "+_vm._s(item.address)+" ")])]}},{key:"item.phone",fn:function({ item }){return [_c('span',{style:({ textDecoration: item.deleted_at ? 'line-through' : 'none' })},[_vm._v(" "+_vm._s(item.phone.replaceAll('|', ', '))+" ")])]}},{key:"item.email",fn:function({ item }){return [_c('span',{style:({ textDecoration: item.deleted_at ? 'line-through' : 'none' })},[_vm._v(" "+_vm._s(item.email)+" ")])]}},(_vm.isEditing)?{key:"item.action",fn:function({ item, index }){return [(!item.deleted_at)?_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":!_vm.isEditing || !!item.deleted_at,"x-small":"","fab":"","depressed":"","color":"primary","outlined":""},on:{"click":function($event){_setup.openEditManufacturerModal();
          _setup.bindManufacturer(index);
          _setup.manufacturerIndex = index;}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(!item.deleted_at)?_c('v-btn',{attrs:{"disabled":!_vm.isEditing || !!item.deleted_at,"x-small":"","fab":"","depressed":"","outlined":"","color":"error"},on:{"click":function($event){_setup.openDeleteManufacturerModal();
          _setup.manufacturerIndex = index;}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_c('v-btn',{attrs:{"disabled":!_vm.isEditing || !item.deleted_at,"x-small":"","fab":"","depressed":"","outlined":"","color":"primary"},on:{"click":function($event){_setup.openRescueManufacturerModal();
          _setup.manufacturerIndex = index;}}},[_c('v-icon',[_vm._v("mdi-delete-clock-outline")])],1)]}}:null,{key:"foot",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"5"}},[(_vm.isEditing)?_c('v-btn',{attrs:{"block":"","outlined":"","color":"primary"},on:{"click":_setup.openAddManufacturerModal}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v("製造者情報を追加 ")],1):_vm._e()],1)])]},proxy:true}],null,true)}),(_vm.isEditing)?_c('v-dialog',{attrs:{"width":"500"},model:{value:(_setup.modalDeleteManufacturer),callback:function ($$v) {_setup.modalDeleteManufacturer=$$v},expression:"modalDeleteManufacturer"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 lighten-2"},[_vm._v(" 本当に削除しますか ")]),_c('v-divider'),_c('v-card-text',[_vm._v(" 「削除する」をクリックすると、データに削除フラグが付与されます。"),_c('br')]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_setup.deleteManufacturerData();
            _setup.closeDeleteManufacturerModal();}}},[_vm._v(" 削除する ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"cancel","text":""},on:{"click":_setup.closeDeleteManufacturerModal}},[_vm._v(" 戻る ")])],1)],1)],1):_vm._e(),(_setup.modalRescueManufacturer)?_c('v-dialog',{attrs:{"width":"500"},model:{value:(_setup.modalRescueManufacturer),callback:function ($$v) {_setup.modalRescueManufacturer=$$v},expression:"modalRescueManufacturer"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 lighten-2"},[_vm._v(" 第1章製造者のデータの削除フラグを外しますか ")]),_c('v-divider'),_c('v-card-text',[_vm._v(" 「削除フラグを外す」をクリックすると、データに付与された削除フラグが外されます。"),_c('br')]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"cancel","text":""},on:{"click":function($event){return _setup.closeRescueManufacturerModal()}}},[_vm._v(" 戻る ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_setup.rescueManufacturerData();
            _setup.closeRescueManufacturerModal();}}},[_vm._v(" 削除フラグを外す ")])],1)],1)],1):_vm._e(),(_setup.modalAddManufacturer)?_c('v-dialog',{attrs:{"width":"1200"},model:{value:(_setup.modalAddManufacturer),callback:function ($$v) {_setup.modalAddManufacturer=$$v},expression:"modalAddManufacturer"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 lighten-2"},[_vm._v(" 第1章製造者のデータを編集してください ")]),_c('v-divider'),_c('v-card-text',[_c(_setup.SdsSection1ManufacturerForm,{attrs:{"is-valid":_setup.isValidAddManufacturer,"is-editing":_vm.isEditing,"name":_setup.newManufacturer.name,"address":_setup.newManufacturer.address,"phone":_setup.newManufacturer.phone,"email":_setup.newManufacturer.email},on:{"update:isValid":function($event){_setup.isValidAddManufacturer=$event},"update:is-valid":function($event){_setup.isValidAddManufacturer=$event},"update:name":function($event){return _vm.$set(_setup.newManufacturer, "name", $event)},"update:address":function($event){return _vm.$set(_setup.newManufacturer, "address", $event)},"update:phone":function($event){return _vm.$set(_setup.newManufacturer, "phone", $event)},"update:email":function($event){return _vm.$set(_setup.newManufacturer, "email", $event)}}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"cancel","text":""},on:{"click":function($event){return _setup.closeAddManufacturerModal()}}},[_vm._v(" 戻る ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_setup.isValidAddManufacturer},on:{"click":function($event){_setup.addManufacturer();
            _setup.clearNewSection1ManufacturerForm();
            _setup.closeAddManufacturerModal();}}},[_vm._v(" 追加する ")])],1)],1)],1):_vm._e(),(_setup.modalEditManufacturer)?_c('v-dialog',{attrs:{"width":"1200"},model:{value:(_setup.modalEditManufacturer),callback:function ($$v) {_setup.modalEditManufacturer=$$v},expression:"modalEditManufacturer"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 lighten-2"},[_vm._v(" 第1章製造者のデータを編集してください ")]),_c('v-divider'),_c('v-card-text',[_c(_setup.SdsSection1ManufacturerForm,{attrs:{"is-valid":_setup.isValidEditManufacturer,"is-editing":_vm.isEditing,"name":_setup.updatedManufacturer.name,"address":_setup.updatedManufacturer.address,"phone":_setup.updatedManufacturer.phone,"email":_setup.updatedManufacturer.email},on:{"update:isValid":function($event){_setup.isValidEditManufacturer=$event},"update:is-valid":function($event){_setup.isValidEditManufacturer=$event},"update:name":function($event){return _vm.$set(_setup.updatedManufacturer, "name", $event)},"update:address":function($event){return _vm.$set(_setup.updatedManufacturer, "address", $event)},"update:phone":function($event){return _vm.$set(_setup.updatedManufacturer, "phone", $event)},"update:email":function($event){return _vm.$set(_setup.updatedManufacturer, "email", $event)}}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"cancel","text":""},on:{"click":function($event){return _setup.closeEditManufacturerModal()}}},[_vm._v(" 戻る ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_setup.editManufacturer();
            _setup.closeEditManufacturerModal();}}},[_vm._v(" 更新する ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }